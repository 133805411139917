<template>
  <section class="section section--shadow-0">
    <div class="section-header">
      <div class="section__controls">
        <div v-if="app.status !== 'CONFIRMED'" class="section__controls-group">
          <a v-if="hasPermission('application.profile.sendCompanies')" href="javascript:void(0)" class="btn btn-sm btn-info" @click="openModal">{{ getTranslation('publishToCompanies') }}</a>
        </div>
      </div>
    </div>
    <div class="section__body">
      <div class="table-responsive table-3-wrapper">
        <table class="table table-borderless">
          <thead class="table-3__head">

          <tr class="table-3__row">
            <td></td>
            <td>
              <form method="get" @submit.prevent="fetchAppCompanies(1)">
                <div class="field-advanced">
                  <div class="field-advanced__inner">
                    <div class="field-advanced__field-wrap">
                      <input v-model="filterForm.companyQuery" class="field  field--full-width  field--bg-grey  js-borderOnFocus" type="text" :placeholder="getTranslation('searchByTitle')">
                    </div>

                    <div class="field-advanced__right">
                      <button class="btn btn-primary">
                        <svg class="btn__icon">
                          <use xlink:href="#icon__search"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </td>
          </tr>
          <tr class="table-3__row">
            <td class="table-3__cell  table__hcell" style="width: 0.2%">
              #
            </td>
            <td class="table-3__cell  table__hcell" style="width: 15%">
              {{ getTranslation('companies') }} - {{ items.length }}
            </td>
            <td v-if="app.status !== 'NEW'" class="table-3__cell  table__hcell" style="width: 7%">
              {{ getTranslation('expirationTime') }}
            </td>
            <td v-if="app.status !== 'NEW'" class="table-3__cell  table__hcell" style="width: 7%">
              {{ getTranslation('shipmentDate') }}
            </td>
            <td v-if="app.status !== 'NEW'" class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('price') }}
            </td>
            <td v-if="app.status !== 'NEW'" class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('charged') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 15%">
            </td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item ,key) in items" v-bind:key="item.id" class="table-3__row">
            <td class="table-3__cell font-weight-bold">
              {{ item.id }}
            </td>
            <td class="table-3__cell">
              {{ item.company.title }}
              <span v-if="item.company.system_default" class="badge badge-info">{{ getTranslation('systemDefault') }}</span>
            </td>
            <td v-if="app.status !== 'NEW'" class="table-3__cell">
              {{ item.end_time }}
            </td>
            <td v-if="app.status !== 'NEW'" class="table-3__cell">
              <span v-if="item.shipment_date">{{ item.shipment_date }} </span>
              <span v-else class="text text-danger">--</span>
            </td>
            <td v-if="app.status !== 'NEW'" class="table-3__cell">
              <span v-if="item.price">{{ item.price }}  <span v-if="item.currency">{{ item.currency.code }}</span></span>
              <span v-else class="text text-danger">--</span>
            </td>
            <td v-if="app.status !== 'NEW'" class="table-3__cell">
              {{ item.chargedPrice }} <span v-if="item.currency">{{ item.currency.code }}</span> <span v-if="item.in_percent">({{ item.charged }}%)</span>
            </td>
            <td class="table-3__cell">

              <CIcon v-if="item.confirmedForAgent" custom-classes="c-icon c-icon-2xl text-success" name="cil-check-circle"
                     v-c-tooltip="getTranslation('Your conditions are selected')"
              />
              <CIcon v-else-if="app.status === 'AGENT_SELECTED'" custom-classes="c-icon c-icon-2xl text-warning" name="cil-sync"
                     v-c-tooltip="getTranslation('Your conditions are under inspection')"
              />
              <CIcon v-else-if="item.is_selected && !item.isAgent" custom-classes="c-icon c-icon-2xl text-success" name="cil-check-circle"
                     v-c-tooltip="getTranslation('Selected for cargo')"
              />

              <button v-if="app.status === 'WAITING_PROVIDER' && item.price && hasPermission('application.profile.selectCompany') && !item.is_selected" @click="selectCompany(item.id)"
                      class="btn btn-success btn-square btn-sm" type="button">
                <CIcon name="cil-check"/>
                &nbsp
              </button>
              <button v-if="item.price" class="btn  btn--white  btn--square" @click="setPriceItem(item,true)" style="margin-left: 8px" :title="getTranslation('moreInfo')">
                <svg class="btn__icon  btn__icon--sm">
                  <use xlink:href="#icon__search"></use>
                </svg>
              </button>

              <button v-if="(app.status === 'WAITING_PROVIDER' || app.status === 'AGENT_NEW') && hasPermission('application.profile.savePrice') && !item.is_selected && !item.isPassed" @click="setPriceItem(item)"
                      :title="getTranslation('providePrice')"
                      class="btn btn-sm btn-link text-info underline cursor-pointer">
                {{ getTranslation('change') }}
              </button>
              <button v-if="app.request_status ==='TARIF' &&  hasPermission('application.profile.savePrice') && item.is_selected " @click="setPriceItem(item,false,true)"
                      :title="getTranslation('providePrice')"
                      class="btn btn-sm btn-link text-info underline cursor-pointer">
                {{ getTranslation('change') }}
              </button>

              <span v-if="item.isPassed && !item.is_selected" class="text text-danger">{{ getTranslation('timeIsUp') }}</span>
              <span v-if="item.isPassed && item.isAgent" class="text text-danger">,&nbsp; {{ getTranslation('You did not respond') }}</span>
            </td>
          </tr>
          <tr v-if="items.length < 1" class="table-3__row">
            <td colspan="5">
              <span class="empty">{{ getTranslation('notAddedYet') }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal
        size="lg"
        :title="this.priceItem.company.title"
        :show.sync="showPriceModal"
        :centered="false"
        @modalCancel="closeModal(true)"
        cancel-button-label="close"
        :ok-button-label="infoModal ? '' :'save'"
        @clickOK="savePriceItem(priceItem)"
        scrollable
        :alert-msg="getAlertMSg()"
        :alert-class="'alert-warning'"
    >

      <div class="container-fluid">
        <form method="post" @submit.prevent="savePriceItem">

          <div v-if="!infoModal" class="row">
            <div class="col-md-12 col-sm-12 mb-3">
              <div class="section__title">{{ getTranslation('shipment') }}</div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form__label form__label--block">{{ getTranslation('shipmentDate') }}<span
                    class="required">*</span></label>
                <DatePicker
                    v-model="shipment.shipment_date"
                    valueType="format"
                />
                <div v-if="$v.shipment.shipment_date.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
              </div>

              <div class="form-group">
                <label class="form__label form__label--block">{{ getTranslation('arrivalDate') }}<span
                    class="required">*</span></label>
                <DatePicker
                    v-model="shipment.arrival_date"
                    valueType="format"
                />
                <div v-if="$v.shipment.arrival_date.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
              </div>

              <AirportSelect reduce-option="title" v-if="!app.by_land" form-label="transitDestinations" :edit-item-id="shipment.transit_destinations" multiple v-model="shipment.transit_destinations"/>

              <div class="form-group">
                <label class="form__label form__label--block">{{ getTranslation('shipmentDescription') }} </label>
                <textarea class="field--area field field--short-area" v-model="shipment.shipment_description"></textarea>
              </div>

            </div>
            <div class="col-md-6 col-sm-12">
              <AirportSelect v-if="!app.by_land" required form-label="shipmentDestination" :edit-item-id="shipment.shipment_airport_id" v-model="shipment.shipment_airport_id"/>
              <AirportSelect v-if="!app.by_land" required form-label="arrivalDestination" :edit-item-id="shipment.arrival_airport_id" v-model="shipment.arrival_airport_id"/>

              <AirlineSelect :edit-item-id="shipment.airline_id" v-model="shipment.airline_id" placeholder="shipper" required :active="!infoModal"></AirlineSelect>

              <div class="form-group">
                <label class="form__label form__label--block">{{ getTranslation('arrivalDescription') }} </label>
                <textarea class="field--area field field--short-area" v-model="shipment.arrival_description"></textarea>
              </div>
            </div>
          </div>

          <div v-if="infoModal" class="row">
            <div class="section__title">{{ getTranslation('shipment') }}</div>
            <table v-for="item in shipments" v-bind:key="item.id" class="table-doc" :class="{'border-success':item.status && shipments.length > 1}">
              <tbody>
              <tr>
                <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('shipper') }}:</th>
                <td class="table-doc__cell">
                  <span v-if="item.airline_id">{{ item.airline.full_text }}</span> <span v-else>{{ item.shipper }}</span>
                </td>
              </tr>
              <tr v-if="item.shipmentAirport">
                <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('shipmentDestination') }}:</th>
                <td class="table-doc__cell">{{ item.shipmentAirport.fullText }}</td>
                <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('shipmentDate') }}:</th>
                <td class="table-doc__cell">{{ item.shipment_date }}</td>
              </tr>
              <tr v-if="item.arrivalAirport">
                <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('arrivalDestination') }}:</th>
                <td class="table-doc__cell">{{ item.arrivalAirport.fullText }}</td>
                <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('arrivalDate') }}:</th>
                <td class="table-doc__cell">{{ item.arrival_date }}</td>
              </tr>
              <tr v-if="!app.by_land">
                <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('transitDestinations') }}:</th>
                <td class="table-doc__cell">{{ item.transit_destinations }}</td>
              </tr>
              <tr>
                <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('shipmentDescription') }}:</th>
                <td class="table-doc__cell">{{ item.shipment_description }}</td>
              </tr>
              <tr>
                <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('arrivalDescription') }}:</th>
                <td class="table-doc__cell">{{ item.arrival_description }}</td>
              </tr>

              </tbody>
            </table>
          </div>

          <!--    total parameters      -->
          <div class="row border-top pt-3">

            <div class="col-sm-4">
              <div class="c-callout">
                <small class="text-muted">{{ getTranslation('volume') }} </small><br>
                <strong class="h4">{{ totalParameters.totalVolume_m }} m<sup>3</sup> </strong>
              </div>
            </div><!--/.col-->
            <div class="col-sm-4">
              <div class="c-callout">
                <small class="text-muted">{{ getTranslation('weight') }}</small><br>
                <strong class="h4">{{ totalParameters.totalWeight }} {{ totalParameters.w_unit }}</strong>
              </div>
            </div><!--/.col-->
            <div class="col-sm-4">
              <div class="c-callout">
                <small class="text-muted">{{ getTranslation('quantity') }}</small><br>
                <strong class="h4">{{ totalParameters.totalQuantity }}</strong>
              </div>
            </div><!--/.col-->
          </div>
          <!--     end total parameters     -->

          <div v-if="!infoModal && showPriceModal" class="row">
            <div class="col-md-12 col-sm-12 mb-3">
              <div class="section__title">{{ getTranslation('cost') }}</div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form__label form__label--block">{{ getTranslation('priceWeight') }} <span class="required">*</span></label>
                <input type="number" class="field" step="0.1" :class="{'is-invalid':$v.price.price_weight.$error}" min="0" v-model="price.price_weight">
                <div v-if="$v.price.price_weight.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
              </div>
              <div class="form-group">
                <label class="form__label form__label--block">{{ getTranslation('priceTransport') }} <span class="required">*</span></label>
                <input type="number" class="field" step="0.1" :class="{'is-invalid':$v.price.price_transport.$error}" min="0" v-model="price.price_transport">
                <div v-if="$v.price.price_transport.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
              </div>
              <div class="form-group">
                <label class="form__label form__label--block">{{ getTranslation('priceAwb') }} <span class="required">*</span></label>
                <input type="number" class="field" step="0.1" :class="{'is-invalid':$v.price.price_awb.$error}" min="0" v-model="price.price_awb">
                <div v-if="$v.price.price_awb.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
              </div>

              <div class="form-group">
                <label class="form__label">{{ getTranslation('currency') }} <span class="required">*</span></label>
                <v-select
                    :options="currencies"
                    label="code"
                    :class="{'is-invalid':$v.priceItem.currency_id.$error}"
                    :reduce="item=>item.id"
                    v-model="priceItem.currency_id"
                />
                <div v-if="$v.priceItem.currency_id.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
              </div>

            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form__label form__label--block">{{ getTranslation('charged') }} Cargo JET </label>
                <input type="number" :class="{'is-invalid':$v.priceItem.charged.$error}" class="field" step="0.1" min="0.1" :max="priceItem.in_percent ? '50':''" v-model="priceItem.charged">
                <div v-if="$v.priceItem.charged.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
              </div>
              <div class="form-group">
                <div class="form-check cursor-pointer">
                  <input class="form-check-input" :class="{'is-invalid':$v.priceItem.in_percent.$error}" type="checkbox" v-model="priceItem.in_percent" id="percent">
                  <label class="form-check-label form__label cursor-pointer" for="percent">
                    {{ getTranslation('isInPercent') }} ?
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="infoModal">
            <div v-for="item in prices" v-bind:key="item.id" class="row" :class="{'border-success':item.status && prices.length > 1}">
              <div v-if="prices.length > 1 && item.status" class="col-12"><span class="badge badge-success">{{ getTranslation('Active price') }}</span></div>
              <div class="col-sm-4">
                <div class="c-callout c-callout-primary">
                  <small class="text-muted">{{ getTranslation('priceWeight') }} </small><br>
                  <strong class="h4">{{ item.price_weight }} </strong>
                </div>
              </div><!--/.col-->
              <div class="col-sm-4">
                <div class="c-callout c-callout-primary">
                  <small class="text-muted">{{ getTranslation('priceTransport') }} </small><br>
                  <strong class="h4">{{ item.price_transport }} <span v-if="priceItem.currency">{{ priceItem.currency.code }}</span> </strong>
                </div>
              </div><!--/.col-->
              <div class="col-sm-4">
                <div class="c-callout c-callout-primary">
                  <small class="text-muted">{{ getTranslation('priceAwb') }} </small><br>
                  <strong class="h4">{{ item.price_awb }} <span v-if="priceItem.currency">{{ priceItem.currency.code }}</span> </strong>
                </div>
              </div><!--/.col-->
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 col-sm-6">
              <div class="c-callout c-callout-info b-t-1 b-r-1 b-b-1 align-items-baseline">
                <span class="text-muted">{{ getTranslation('totalCost') }}</span><br>
                <strong class="h4">{{ totalCost }}
                  <span v-if="priceItem.currency_id === 1">KGS</span>
                  <span v-if="priceItem.currency_id === 2">USD</span>
                  <span v-if="priceItem.currency_id === 3">RUB</span>
                </strong>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="c-callout c-callout-success b-t-1 b-r-1 b-b-1">
                <span class="text-muted">{{ getTranslation('profit') }}</span><br>
                <strong class="h4">{{ profit }}
                  <span v-if="priceItem.currency_id === 1">KGS</span>
                  <span v-if="priceItem.currency_id === 2">USD</span>
                  <span v-if="priceItem.currency_id === 3">RUB</span>
                </strong>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="c-callout c-callout-warning b-t-1 b-r-1 b-b-1">
                <span class="text-muted">{{ getTranslation('charged') }}</span><br>
                <strong v-if="!priceItem.in_percent" class="h4">{{ charged }}
                  <span v-if="priceItem.currency_id === 1">KGS</span>
                  <span v-if="priceItem.currency_id === 2">USD</span>
                  <span v-if="priceItem.currency_id === 3">RUB</span>
                </strong>
                <strong v-else class="h4">{{ chargeInPercent }}
                  <span v-if="priceItem.currency_id === 1">KGS</span>
                  <span v-if="priceItem.currency_id === 2">USD</span>
                  <span v-if="priceItem.currency_id === 3">RUB</span>
                </strong>
              </div>
            </div>
          </div>

          <div v-if="infoModal" class="row border-top pt-3">
            <div class="section__title">{{ getTranslation('extraCost') }}</div>
            <table class="table-doc">
              <tbody>
              <tr v-for="item in extraPrices" v-bind:key="item.id" :class="{'border-success':item.status && extraPrices.length > 1}">
                <th class="table-doc__cell table-doc__cell--head">{{ item.title }}:</th>
                <td class="table-doc__cell">{{ item.value }}{{ item.currency.code }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div v-if="!infoModal" class="row border-top pt-3">
            <div class="col-md-12 col-sm-12 mb-3">
              <div class="section__title">{{ getTranslation('extraCost') }}
                <a href="javascript:void(0)" :title="getTranslation('add')" class="btn  btn--system btn--white btn-sm"
                   @click="addExtraPrice">
                  <svg class="btn__icon  btn__icon--sm">
                    <use xlink:href="#icon__square-add"></use>
                  </svg>
                  {{ getTranslation('add') }}
                </a>
              </div>
            </div>
            <div class="row field--full-width" v-for="(itemExtra,key) in extraPrices">
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                      <label class="form__label form__label--block">{{ getTranslation('title') }}<span class="required">*</span> </label>
                      <input type="text" class="field" v-model="itemExtra.title" :class="{'is-invalid':$v.extraPrice.title.$error}">
                      <div v-if="$v.extraPrice.title.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <div class="form-group">
                      <label class="form__label form__label--block">{{ getTranslation('cost') }}<span class="required">*</span> </label>
                      <input type="number" class="field" step="0.1" min="0" v-model="itemExtra.value" :class="{'is-invalid':$v.extraPrice.value.$error}">
                      <div v-if="$v.extraPrice.value.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <div class="form-group">
                      <label class="form__label">{{ getTranslation('currency') }} <span class="required">*</span></label>
                      <v-select
                          :options="currencies"
                          label="code"
                          :class="{'is-invalid':$v.extraPrice.currency_id.$error}"
                          :reduce="item=>item.id"
                          v-model="itemExtra.currency_id"
                      />
                      <div v-if="$v.extraPrice.currency_id.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                    </div>
                  </div>
                  <div class="col-md-1 col-sm-12">
                    <div class="form-group">
                      <a href="javascript:void(0)" :title="getTranslation('cancel')" class="btn  btn--system btn--white"
                         @click="()=>{extraPrices.splice(key,1)}">
                        <svg class="btn__icon  btn__icon--sm btn__icon--danger">
                          <use xlink:href="#icon__square-minus"></use>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">

              </div>
            </div>
          </div>
          <div class="row border-top">
            <div v-if="priceItem.modifiedUser" class="col-md-4 col-sm-12">
              <h4 class="section__title">{{ getTranslation('user') }}</h4>
              <p class="text"> {{ priceItem.modifiedUser.name }} </p>
            </div>
            <div v-if="priceItem.updated_at" class="col-md-4 col-sm-12">
              <h4 class="section__title">{{ getTranslation('updatedAt') }}</h4>
              <p class="text"> {{ priceItem.updated_at }} </p>
            </div>
            <div v-if="infoModal && priceItem.extra_description" class="col-md-12 col-sm-12">
              <h4 class="section__title">{{ getTranslation('extraCostDescription') }}</h4>
              <p class="text"> {{ priceItem.extra_description }} </p>
            </div>
          </div>
        </form>
      </div>
    </modal>
    <modal
        :title="this.getTranslation('publishToCompanies')"
        :show.sync="showModal"
        :centered="false"
        @modalCancel="closeModal"
        cancel-button-label="close"
    >
      <ul class="nav nav-pills nav-justified">
        <li class="nav-item">
          <a class="nav-link" :class="{'active':tabs.suitableCompanies}" href="#suitable-companies" @click="tabSelect('suitableCompanies','suitableCompanies',false)">{{ getTranslation('suitableCompanies') }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{'active':tabs.allCompanies}" href="#all-companies" @click="tabSelect('allCompanies','allCompanies',false)">{{ getTranslation('allCompanies') }}</a>
        </li>
      </ul>
      <div class="tab-content">
        <div v-if="hasPermission('application.profile.suitableCompanies')" id="suitable-companies" :class="{'show active':tabs.suitableCompanies}" class="tab-pane fade">
          <table class="table table-borderless table-hover mt-2">
            <thead class="thead-light">
            <tr>
              <th scope="col" style="width: 3%">#</th>
              <th scope="col" style="width: 50%">
                <form method="get" @submit.prevent="fetchSuitableCompanies(appId,1)">
                  <div class="field-advanced">
                    <div class="field-advanced__inner">
                      <div class="field-advanced__field-wrap">
                        <input v-model="filterForm.companyQuery" class="field  field--full-width  field--bg-grey  js-borderOnFocus" type="text" :placeholder="getTranslation('searchByTitle')">
                      </div>

                      <div class="field-advanced__right">
                        <button class="btn btn-primary">
                          <svg class="btn__icon">
                            <use xlink:href="#icon__search"/>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </th>
              <th scope="col" style="width: 5%">
                <a v-if="app.status === 'NEW' && hasPermission('application.profile.setCompanies')" href="javascript:void(0)" @click="chooseAll('suitable')"
                   class="btn btn-sm btn-square btn-primary">{{ getTranslation('chooseAll') }}</a>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in suitableCompanies" v-bind:key="item.id">
              <th scope="row">{{ item.id }}</th>
              <td>{{ item.title }} <span v-if="item.system_default" class="badge badge-info">{{ getTranslation('systemDefault') }}</span></td>
              <td>
                <a v-if="!item.isAppSend && hasPermission('application.profile.setCompanies')" href="javascript:void(0)" @click="setCompanies(item.id)" class="btn btn-sm btn-primary">{{ getTranslation('choose') }}</a>
                <a href="javascript:void(0)" v-if="item.isAppSend && hasPermission('application.profile.removeCompanies')" @click="removeCompanies(item.id)" class="btn btn-sm btn-danger">
                  <CIcon name="cil-minus"/>
                  &nbsp
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-if="hasPermission('application.profile.allCompanies')" id="all-companies" :class="{'show active':tabs.allCompanies}" class="tab-pane fade">
          <table class="table table-borderless table-hover mt-2">
            <thead class="thead-light">
            <tr>
              <th scope="col" style="width: 3%">#</th>
              <th scope="col" style="width: 50%">
                <form method="get" @submit.prevent="fetchCompanies(1)">
                  <div class="field-advanced">
                    <div class="field-advanced__inner">
                      <div class="field-advanced__field-wrap">
                        <input v-model="filterForm.companyQuery" class="field  field--full-width  field--bg-grey  js-borderOnFocus" type="text" :placeholder="getTranslation('searchByTitle')">
                      </div>

                      <div class="field-advanced__right">
                        <button class="btn btn-primary">
                          <svg class="btn__icon">
                            <use xlink:href="#icon__search"/>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </th>
              <th v-if="app.status==='NEW' && hasPermission('application.profile.setCompanies')" scope="col" style="width: 5%">
                <a href="javascript:void(0)" @click="chooseAll('all')" class="btn btn-sm btn-square btn-primary">
                  {{ getTranslation('chooseAll') }}
                </a>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in companies" v-bind:key="item.id">
              <th scope="row">{{ item.id }}</th>
              <td>{{ item.title }} <span v-if="item.system_default" class="badge badge-info">{{ getTranslation('systemDefault') }}</span></td>
              <td v-if="hasPermission('application.profile.setCompanies')">
                <a v-if="!item.isAppSend" href="javascript:void(0)" @click="setCompanies(item.id)" class="btn btn-sm btn-primary">{{ getTranslation('choose') }}</a>
                <a href="javascript:void(0)" @click="removeCompanies(item.id)" v-if="item.isAppSend && hasPermission('application.profile.removeCompanies')" class="btn btn-sm btn-danger">
                  <CIcon name="cil-minus"/>
                  &nbsp
                </a>
              </td>
            </tr>
            </tbody>
          </table>
          <CPagination
              v-if="companyPagination.last_page > 1"
              class="mt-3"
              :active-page.sync="companyPagination.current_page"
              :pages="companyPagination.last_page"
          />
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import required from "vuelidate/lib/validators/required";
import AirportSelect from "../../../../components/shared/AirportSelect";
import AirlineSelect from "../../../../components/shared/AirlineSelect";

export default {
  name: "AppCompaniesComponent",
  components: { AirlineSelect, AirportSelect },
  props: {
    active: {
      default: false
    },
    appId: {
      required: true
    },
    app: {
      type: Object,
      required: true
    },
    latestTarif: Object
  },
  computed: {
    profit() {

      let sum = 0;

      if ( this.priceItem.in_percent )
        sum = this.totalCost - this.chargeInPercent;
      else
        sum = this.totalCost - this.charged;


      return sum.toFixed(2);

    },
    charged() {

      let sum = 0;

      if ( this.priceItem.charged )
        sum = parseFloat(this.priceItem.charged);


      return sum.toFixed(2);

    },
    totalCost() {
      let sum = 0;

      if ( !this.infoModal ) {
        if ( !this.price.price_weight || !this.price.price_transport )
          return sum;

        sum = parseFloat(this.price.price_weight) * parseFloat(this.price.price_transport);

        if ( this.price.price_awb )
          sum += parseFloat(this.price.price_awb);
      } else {

        for ( let item of this.prices ) {

          if ( !item.status )
            continue;

          if ( !item.price_weight || !item.price_transport )
            continue;

          sum = parseFloat(item.price_weight) * parseFloat(item.price_transport);

          if ( item.price_awb )
            sum += parseFloat(item.price_awb);
        }

      }


      return sum.toFixed(2);
    },
    chargeInPercent() {

      let sum = 0;

      if ( !this.totalCost || !this.priceItem.charged )
        return sum;


      sum = this.totalCost;

      let percentage = parseFloat(this.priceItem.charged) / 100;

      sum = sum * percentage;


      return sum.toFixed(2);
    }
  },
  watch: {
    'tabs.suitableCompanies'(val) {
      if ( val ) {
        this.fetchSuitableCompanies(this.appId, this.suitableCompanyPagination.current_page);
      }
    },
    'tabs.allCompanies'(val) {
      if ( val ) {
        this.fetchCompanies(this.companyPagination.current_page);
      }
    },
    'companyPagination.current_page'(val) {
      if ( val ) {
        this.fetchCompanies(val);
      }
    },
    'suitableCompanyPagination.current_page'(val) {
      if ( val ) {
        this.fetchSuitableCompanies(this.appId, val);
      }
    },
    'pagination.current_page'(val) {
      if ( val ) {
        this.fetchAppCompanies(val);
      }
    },
    'active'(val) {
      if ( val )
        this.fetchAppCompanies();
    },
  },
  data() {
    return {
      items: [],
      customValidation: {
        shipment: {
          shipment_airport_id: true,
          arrival_airport_id: true,
        }
      },
      pagination: {
        total: 1,
        last_page: 1,
        current_page: 1
      },
      infoModal: false,
      suitableCompanies: [],
      companies: [],
      companyPagination: {
        total: 1,
        last_page: 1,
        current_page: 1
      },
      suitableCompanyPagination: {
        total: 1,
        last_page: 1,
        current_page: 1
      },
      tabs: {
        suitableCompanies: false,
        allCompanies: false
      },
      showModal: false,
      showPriceModal: false,
      filterForm: {
        companyQuery: ''
      },
      priceItem: {
        currency_id: '',
        company: {},
        charged: 0,
      },
      shipments: [],
      prices: [],
      shipment: {
        transit_destinations: [],
        shipper: '',
        shipment_date: '',
        arrival_date: '',
        airline_id: '',
        arrival_airport_id: '',
        shipment_airport_id: '',
        shipment_description: '',
        arrival_description: '',
      },
      price: {
        price_weight: '',
        price_transport: '',
        price_awb: '',
      },
      extraPrices: [],
      extraPrice: {
        title: '',
        value: '',
        currency_id: '',
        description: '',
      },
      currencies: [],
      totalParameters: {
        totalWeight: '',
        totalQuantity: '',
        v_unit: '',
        w_unit: '',
      },
      sendTarif: false,
      modalAlertMsg: '',
    }

  },
  created() {
    this.fetchCurrencies();

  },
  methods: {
    getAlertMSg() {
      let msg = this.sendTarif ? 'Tarif is requested' : '';

      if ( this.sendTarif && this.latestTarif && this.latestTarif.id ) {
        msg = msg + ' ' + this.latestTarif.tarif + ' '+this.latestTarif.currency;
      }
      return msg;
    },
    resetShipment() {
      this.shipment = {
        transit_destinations: [],
        shipper: '',
        shipment_date: '',
        arrival_date: '',
        airline_id: '',
        arrival_airport_id: '',
        shipment_airport_id: '',
        shipment_description: '',
        arrival_description: '',
      };
    },

    resetExtraPrices() {
      this.extraPrice = {
        title: '',
        value: '',
        currency_id: '',
        description: '',
      };
      this.extraPrices = [];
    },

    resetPrice() {
      this.price = {
        price_weight: '',
        price_transport: '',
        price_awb: '',
      };
    },

    addExtraPrice() {
      this.extraPrices.push({ ...this.extraPrice });
    },

    selectCompany(id) {


      Swal.fire({
        title: this.getTranslation('areYouSure'),
        text: this.getTranslation("selectCurrentAgent"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.getTranslation('OK')
      }).then((result) => {

        if ( result.isConfirmed ) {


          this.axios.post(this.$urls.application.selectCompany.url.replace(':id', this.appId), { appCompanyId: id })
              .then(response => {

                this.snotifySuccess('operationSuccess');

                this.fetchAppCompanies();

                this.$emit('updateApp');
              })
              .catch(() => {
              });

        }
      });
    },

    setPriceItem(item, moreInfo = false, sendTarif = false) {
      this.$v.priceItem.$reset();

      this.priceItem = { ...item };

      this.sendTarif = sendTarif;

      if ( this.priceItem.currency ) {
        this.priceItem.currency_id = this.priceItem.currency.id;
      }

      this.infoModal = moreInfo;
      this.showPriceModal = true;

      this.fetchTotalParameters(this.appId);

      this.fetchShipments(item.id).then(() => {
        if ( this.shipments.length > 0 )
          this.shipment = { ...this.shipments[this.shipments.length - 1] };

      });
      this.fetchPrices(item.id).then(() => {
        if ( this.prices.length > 0 )
          this.price = { ...this.prices[this.prices.length - 1] };
      });
      this.fetchExtraPrices(item.id);
    },

    fetchTotalParameters(appId) {
      this.axios.get(this.$urls.application.totalParameters.url.replace(':id', appId))
          .then(response => {
            this.totalParameters = response.data.data;
          }).catch(() => {
      });
    },

    fetchShipments(appCompanyId) {
      return this.axios.get(this.$urls.application.appCompany.shipments.url.replace(':id', appCompanyId))
          .then(response => {
            this.shipments = response.data.data;
          }).catch(() => {
          });

    },

    fetchPrices(appCompanyId) {
      return this.axios.get(this.$urls.application.appCompany.prices.url.replace(':id', appCompanyId))
          .then(response => {
            this.prices = response.data.data;
          }).catch(() => {
          });

    },

    fetchExtraPrices(appCompanyId) {
      return this.axios.get(this.$urls.application.appCompany.extraPrices.url.replace(':id', appCompanyId))
          .then(response => {
            this.extraPrices = response.data.data;
          }).catch(() => {
          });

    },

    fetchCurrencies() {
      this.axios.get(this.$urls.application.currencies.url)
          .then(response => {
            this.currencies = response.data.data;
          }).catch(() => {
      });
    },

    savePriceItem() {

      if ( this.infoModal )
        return;

      let commonParams = {
        sendTarif: this.sendTarif ? 1 : 0
      };

      this.$v.shipment.$touch();
      this.$v.price.$touch();

      if ( this.$v.shipment.$invalid )
        return this.snotifyError('Check shipment data');


      if ( this.$v.price.$invalid )
        return this.snotifyError('Check cost data');

      if ( !this.shipment.arrival_airport_id && !this.app.by_land ) {
        this.customValidation.shipment.arrival_airport_id = false;
        return this.snotifyError('Check arrival destination');
      }

      if ( !this.shipment.shipment_airport_id && !this.app.by_land ) {
        this.customValidation.shipment.shipment_airport_id = false;
        return this.snotifyError('Check shipment destination');
      }

      if ( this.extraPrices.length > 0 ) {
        for ( let item of this.extraPrices ) {

          this.extraPrice = { ...item };

          this.$v.extraPrice.$touch();

          if ( this.$v.extraPrice.$invalid )
            return this.snotifyError('Check extra cost data');

        }

      }

      this.priceItem.price = this.totalCost;
      this.priceItem.shipment_date = this.shipment.shipment_date;

      this.$v.priceItem.$touch();

      if ( this.$v.priceItem.$invalid )
        return this.snotifyError('wrongFormMsg');

      let error = false;

      this.axios.post(this.$urls.application.appCompany.addShipment.url.replace(':id', this.priceItem.id), this.shipment, { params: commonParams })
          .then(response => {
            this.shipment.id = response.data.data.id;

            this.axios.post(this.$urls.application.appCompany.addPrice.url.replace(':id', this.priceItem.id), this.price, { params: commonParams })
                .then(response => {
                  this.price.id = response.data.data.id;

                  this.axios.post(this.$urls.application.appCompany.addExtraPrice.url.replace(':id', this.priceItem.id), {
                    extraPrices: this.extraPrices
                  }, { params: commonParams }).then(response => {

                    this.axios.post(this.$urls.application.savePrice.url.replace(':id', this.appId), this.priceItem, { params: commonParams })
                        .then(async response => {

                          this.$emit('updateApp');

                          this.$v.$reset();
                          this.closeModal(true);

                          this.snotifySuccess('saveSuccessMsg');

                          if ( this.hasPermission('application.profile.allCompanies') )
                            this.fetchCompanies(1);


                        }).catch(async () => {
                    });

                  }).catch(() => {
                    error = true;
                  });

                }).catch(() => {
              error = true;
            });

          }).catch(() => {
        error = true;
      });


    },

    fetchAppCompanies(page = null) {

      let params = this.cleanObject(this.filterForm);

      if ( page )
        params.page = page;


      this.axios.get(this.$urls.application.appCompanies.url.replace(':id', this.appId), { params: params })
          .then(response => {
            this.items = response.data.data.items;
            this.pagination = response.data.data.pagination;
          }).catch(() => {
      });

    },

    closeModal(price = false) {
      this.showModal = false;

      if ( price ) {

        this.priceItem = {
          company: {}, currency_id: ''
        };

        this.resetExtraPrices();
        this.resetPrice();
        this.resetShipment();
        this.$v.$reset();

        this.showPriceModal = false;

        setTimeout(function () {
          this.infoModal = false;
        }, 2000);

      }
      this.filterForm.companyQuery = '';
      this.fetchAppCompanies(1);
    },

    openModal() {
      this.showModal = true;
      this.tabSelect('suitableCompanies', 'suitableCompanies', false)
    },

    fetchSuitableCompanies(appId, page = null) {

      let params = {};

      if ( page )
        params.page = page;

      if ( this.filterForm.companyQuery && this.tabs.suitableCompanies )
        params.companyQuery = this.filterForm.companyQuery;


      return this.axios.get(this.$urls.application.suitableCompanies.url.replace(':id', appId), { params: params }).then(response => {
        this.suitableCompanies = response.data.data.items;
        this.suitableCompanyPagination = response.data.data.pagination;
      }).catch(() => {
      });
    },

    fetchCompanies(page = null) {

      let params = {};

      if ( page )
        params.page = page;

      if ( this.filterForm.companyQuery && this.tabs.allCompanies )
        params.query = this.filterForm.companyQuery;


      return this.axios.get(this.$urls.application.allCompanies.url.replace(':id', this.appId), { params: params }).then(response => {
        this.companies = response.data.data.items;
        this.companyPagination = response.data.data.pagination;
      }).catch(() => {
      });
    },

    chooseAll(companyType = 'suitable') {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.getTranslation('OK')
      }).then((result) => {

        if ( result.isConfirmed ) {

          this.axios.post(this.$urls.application.setCompanies.url.replace(':id', this.appId), { type: companyType }).then(response => {
            this.snotifySuccess('operationSuccess');

            if ( companyType === 'suitable' )
              this.fetchSuitableCompanies(this.appId, this.suitableCompanyPagination.current_page);
            else
              this.fetchCompanies(this.companyPagination.current_page);


          }).catch(() => {
          });

        }
      })
    },

    setCompanies(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.getTranslation('OK')
      }).then((result) => {

        if ( result.isConfirmed ) {

          this.axios.post(this.$urls.application.setCompanies.url.replace(':id', this.appId), { company_id: id })
              .then(response => {
                this.snotifySuccess('operationSuccess');

                this.fetchSuitableCompanies(this.appId, this.suitableCompanyPagination.current_page);
                this.fetchCompanies(this.companyPagination.current_page);

              }).catch(() => {
          });

        }
      })
    },

    removeCompanies(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.getTranslation('OK')
      }).then((result) => {

        if ( result.isConfirmed ) {

          this.axios.post(this.$urls.application.removeCompanies.url.replace(':id', this.appId), { company_id: id })
              .then(response => {

                this.snotifySuccess('operationSuccess');

                this.fetchSuitableCompanies(this.appId, this.suitableCompanyPagination.current_page);
                this.fetchCompanies(this.companyPagination.current_page);


              }).catch(() => {
          });

        }
      })
    }
  },
  validations: {
    priceItem: {
      price: {
        required
      },
      currency_id: {
        required
      },
      in_percent: {
        required
      },
      charged: {
        required
      },
      shipment_date: {
        required
      }
    },
    shipment: {
      shipment_date: { required },
      airline_id: { required },
      arrival_date: { required },
    },
    price: {
      price_weight: { required },
      price_awb: { required },
      price_transport: { required },
    },
    extraPrice: {
      title: { required },
      value: { required },
      currency_id: { required },
    }
  }
}
</script>

<style scoped>

</style>